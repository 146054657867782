.chunksList {
  margin: 0;
  padding: 0;

  list-style-type: none;

  .chunkListItem {
    border-bottom: 1px solid #ededed;
    padding: 0 1rem;

    &:first-child {
      border-top: 1px solid #ededed;
    }

    & > a {
      display: inline-block;

      box-sizing: border-box;
      width: 100%;

      color: #606770;
      line-height: 43px;
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      vertical-align: middle;

      &,
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.chunkContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;

  .topSection {
    .translationDirectionSwitch {
      text-align: center;
    }
  }

  .bottomSection {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    .button {
      flex: 0 0 calc((100% - 16px) / 3);
      max-width: calc((100% - 16px) / 3);

      &.buttonCenter {
        margin: 0 8px;
      }
    }
  }
}

.words-count {
  margin-top: 0;

  text-align: left;
}

.words-count {
  margin-bottom: 12px;

  font-family: DejaVu Sans Mono, sans-serif;
  font-size: 14px;
}

.buttonFullWidth {
  max-width: 100%;
  width: 100%;
  text-align: center;
}

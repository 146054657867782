.bundlesPage {
  height: 100%;
  padding: 0 16px;

  &::before {
    content: '';
    display: block;
    overflow: auto;
  }
}

.bundlesPageContent {
  height: calc(100% - #{var(--breadcrumbs-height)});
}

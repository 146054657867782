.header {
  font-size: 24px;
}

.bundlesList {
  margin: 0;
  padding: 0.5rem;

  list-style-type: none;
  text-align: left;
}

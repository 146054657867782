* {
  box-sizing: border-box;
}

html {
  height: 100%;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
}

body {
  box-sizing: border-box;
  height: 100%;
  margin: 0;

  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.root {
  height: 100%;
}

ul {
  margin: 0;
  padding: 0;

  list-style-type: none;
}

$btn-height: 38px;
$btn-primary-color: #33c3f0;
$btn-primary-font-color: #fff;
$btn-secondary-color: #bbb;
$btn-secondary-font-color: #555;

.btn {
  display: inline-block;

  height: $btn-height;
  padding: 0 30px;
  border: 1px solid;
  border-radius: 4px;

  font-size: 11px;
  line-height: $btn-height;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.btnPrimary {
  background-color: $btn-primary-color;
  border-color: $btn-primary-color;
  color: $btn-primary-font-color;
}

.btnPrimaryOutline {
  background-color: #fff;
  border-color: $btn-primary-color;
  color: $btn-primary-color;
}

.btnSecondary {
  background-color: $btn-secondary-color;
  border-color: $btn-secondary-color;
  color: #fff;
}

.btnSecondaryOutline {
  background-color: #fff;
  border-color: $btn-secondary-color;
  color: $btn-secondary-font-color;
}

body {
  margin: 0;
}

.App {
  box-sizing: border-box;
  height: 100%;

  text-align: center;

  // hack to prevent parent container to be pushed down if child has top margin
  padding-top: 1px;
  margin-top: -1px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.toggleButton {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .buttonLeft,
  .buttonRight {
    flex-grow: 0;
    flex-shrink: 0;
    height: 38px;
    padding: 0 15px;

    background-color: #fff;
    border: 1px solid;
    color: #4f4f4f;
    font-size: 11px;
    font-weight: 600;
    line-height: 38px;
    text-transform: uppercase;
    letter-spacing: 1px;

    &.active {
      background-color: #f2f8fc;
      border-color: #3491cf;
      color: #3491cf;
    }

    &:not(.active) {
      border-color: #c7c7c7;
    }
  }

  .buttonLeft {
    border-radius: 4px 0 0 4px;

    &:not(.active) {
      border-right: 0;
    }
  }

  .buttonRight {
    border-radius: 0 4px 4px 0;

    &:not(.active) {
      border-left: 0;
    }
  }
}
